<!--  -->
<template>
  <div class="job_manage">
    <div class="job_manage_in">

      <!-- 企业未完善 -->
      <div v-if="firm_status == 1">

        <div class="noCompany">
          <img src="@/assets/images/B_user/noCompany2.png" alt="">
          <p>您还未完善企业信息，完善后可发布岗位！</p>
          <Button style="background:#FC4455;color:#fff" size="large" @click="go('firm_data','企业资料')">完善企业信息</Button>

        </div>
      </div>
      <!-- 企业待审 -->
      <div v-else-if="firm_status ==2">

        <div class="noCompany">
          <img src="@/assets/images/B_user/pending.png" alt="">
          <p>您企业资料正在审核中，请耐心等待！</p>
        </div>

      </div>
      <!-- 企业不通过 -->
      <div v-else-if="firm_status ==3">
        <div class="noCompany">
          <img src="@/assets/images/B_user/lose.png" alt="">
          <p>您的企业资料未通过审核，请前去修改！</p>
          <Button style="background:#FC4455;color:#fff" size="large" @click="go('firm_data','企业资料')">查看原因</Button>

        </div>

      </div>
      <!-- 企业未发布 -->
      <div v-else-if="firm_status ==4">
        <div class="noCompany">
          <img src="@/assets/images/B_user/unpublished.png" alt="">
          <p>您还未发布岗位，前去发布！</p>
          <Button style="background:#FC4455;color:#fff" size="large" @click="go('job_full','岗位管理')">发布岗位</Button>
        </div>

      </div>
      <!-- 企业已发布 -->
      <div v-else-if="firm_status ==5">
        <section>
          <Tabs :animated="false" v-model="status" @on-click="changeTabs">
            <TabPane :label="label1" name="3"></TabPane>
            <TabPane :label="label2" name="1"></TabPane>
            <TabPane :label="label3" name="2"></TabPane>
            <TabPane :label="label4" name="0"></TabPane>
          </Tabs>
          <div class="right">
            <Button type="primary" @click="$router.push({name:'job_full'})">发布职位</Button>
          </div>
        </section>

        <article>
          <Table max-height="640" :loading="loading" :columns="columns1" :data="Tabledata">

            <!-- 操作 -->
            <template slot-scope="{ row }" slot="details">
              <Button v-show="+status" type="error" size="small" @click="remove(row,0)">下架</Button>
              <Button type="primary" size="small" style="margin:0 5px" @click="$router.push({name:'job_full',query:{id:row._id}})">{{+status?'修改':'重新发布'}}</Button>

              <Button v-show="status == 3 && firm_data.type && firm_data.type !=1" type="primary" size="small" @click="Refresh(row)">刷新</Button>

              <Button v-show="status != 3" type="error" size="small" @click="remove(row,-1)">删除</Button>

            </template>

          </Table>
        </article>

      </div>
    </div>
  </div>
</template>

<script>
import { data1, data2, data3, data4 } from "./Form_head";
export default {
  data() {
    return {
      label1: (h, params) => {
        return h('div', [
          h('span', '已通过'),
          h('span', ' . ' + this.statusCount[0])
        ])
      },
      label2: (h, params) => {
        return h('div', [
          h('span', '审核中'),
          h('span', ' . ' + this.statusCount[1])
        ])
      },
      label3: (h, params) => {
        return h('div', [
          h('span', '审核未通过'),
          h('span', ' . ' + this.statusCount[2])
        ])
      },
      label4: (h, params) => {
        return h('div', [
          h('span', '已结束'),
          h('span', ' . ' + this.statusCount[3])
        ])
      },
      loading: false,
      firm_status: '',
      firm_data: {},
      status: '3',
      obj: {
        status: 3,
        key: '',
        page: 1,
        size: 10
      },

      statusCount: [],
      total: 0,
      columns1: data1,
      Tabledata: [],
    }
  },

  methods: {
    //改变通过与否
    changeTabs(name) {
      this.status = name
      this.obj.page = 1
      this.columns1 = name != 2 ? data1 : data2
      this.get_data()
    },
    //完善企业信息
    go(router, menu) {
      this.$router.push(router)
      this.$emit("setTitle", menu)
    },

    //删除
    remove(row, num) {
      this.$Modal.confirm({
        title: `是否确定${num == 0 ? '下架' : '删除'}该职位？`,
        closable: true,
        onOk: () => {
          this.app("enterpriseOperators", "disablePosition", {
            _id: row._id,
            status: num,
            mobile: localStorage.getItem('firm_mobile')
          }).then(res => {
            console.log(res);

            if (res.code == 200) {
              this.get_data()
              this.$Message.success(res.message)
            }
          })
        }
      })

    },


    //封装获取数据
    get_data() {
      this.loading = true
      this.obj.enterprieseId = localStorage.getItem('firm_enterpriseId')
      this.obj.status = +this.status
      this.app("enterpriseOperators", "myPositions", this.obj).then(res => {
        console.log(res);
        //是否没发布职位
        res.statusCount.map((item) => {
          if (item) {
            this.firm_status = 5 //修改状态为已发布过职业
          }
        })
        //代表tab数量的数组
        this.statusCount = res.statusCount
        this.total = res.count
        this.Tabledata = res.result
        this.loading = false

      })
    },
  },
  created() {
    this.get_data()
    // 获取公司详情
    this.app('enterpriseOperators', 'getEnterprise', {
      enterpriseId: localStorage.getItem('firm_enterpriseId')
    }).then(res => {
      console.log(res, "企业资料");
      this.firm_data = res.result

      if (!this.firm_data.licenseImage) { //没完善企业信息
        this.firm_status = 1
      } else {

        if (this.firm_data.status == 1) {  //待审
          this.firm_status = 2
        } else if (this.firm_data.status == 2) { //不通过
          this.firm_status = 3
        } else if (this.firm_data.status == 3) { //已通过
          this.firm_status = 4  //默认没发布职位
        }
      }

    })



  }
}
</script>

<style lang="less" scoped>
@import "./job_manage.less";
/deep/.ivu-tabs-ink-bar {
  background-color: red;
}
/deep/.ivu-tabs-tab-active {
  color: #515a6e;
}
</style>
